<template>
  <div>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-if="!CampoObrigatorio"
            :disabled="disabled"
            solo
            :value="dataFormatada"
            style="font-size: 18px"
            readonly
            v-bind="attrs"
            v-on="on"
            :label="label"
            append-icon="mdi-calendar-clock"
        ></v-text-field>
        <v-text-field
            v-else
            :disabled="disabled"
            solo
            :value="dataFormatada"
            style="font-size: 18px"
            readonly
            v-bind="attrs"
            v-on="on"
            :label="label"
            append-icon="mdi-calendar-clock"
        >
        <template v-slot:prepend-inner>
            <img
              width="20"
              height="49"
              alt=""
              src="../../assets/faixaCampoObrigatorio.png"
            />
          </template>
        </v-text-field>
      </template>
      <v-date-picker
          v-model="date"
          locale="pt"
          :min="minDate"
          :max="maxDate"
          no-title
          scrollable
      >
        <v-spacer></v-spacer>
        <v-btn
            text
            color="primary"
            @click="$refs.menu.save(null), menu = false"
        >
          Limpar
        </v-btn>
        <v-btn
            text
            color="primary"
            @click="menu = false"
        >
          Cancelar
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DefaultDateInput',
  data: () => ({
    menu: false,
    modal: false,
    date: new Date().toISOString()
  }),
  props: {
    label: {
      default: 'Data'
    },
    data: {
      default: new Date().toISOString()
    },
    limpaDados:{
      default: 0,
    },
    CampoObrigatorio:{
      default: false,
    },
    disabled:{
      default: false,
    },
    maxDate:{
      default: null,
    },
    minDate:{
      default: null,
    },
  },
  mounted() {
    var dateObject = moment(this.data, "DD/MM/YYYY");
    this.date = dateObject.toISOString();
  },
  computed: {
    dataFormatada() {
      moment.locale('pt-br')
      return this.date ? moment(this.date).format('DD/MM/YYYY') : ''
    },
  },
  watch: {
    date: {
      handler(val) {
          this.$emit("update:returnDate", this.date);
          this.$refs.menu.save(this.date);
      },
    },
    limpaDados(){
      this.date = null
    },
  },
}
</script>

<style>
.cor-voltar {
  color: rgba(0, 0, 0, 0.54) !important;
}

.cor-voltar:hover {
  color: black !important;
}

.icon{
  padding: 0px;
  margin-top: 25px;
}

</style>
