var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"Table"}},[_c('b-table',{ref:"selectableTable",attrs:{"fields":_vm.columns,"filter":_vm.filter,"items":_vm.rows,"hover":"","no-border-collapse":"","responsive":"lg","striped":""},on:{"filtered":function($event){return _vm.filtered($event)}},scopedSlots:_vm._u([{key:"cell(status)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.status ? 'Ativo' : 'Inativo')+" ")]}},{key:"cell(action)",fn:function(row){return [(_vm.edit_button)?_c('div',{staticClass:"btn-table-inner btn-table-inner-edit",on:{"click":function($event){return _vm.$emit('edit-register', {
            row: row,
          })}}},[_c('b-icon-pencil',{staticClass:"icon-table-inner"})],1):_vm._e(),(_vm.redirect_button)?_c('div',{staticClass:"btn-table-inner btn-table-inner-redirect",on:{"click":function($event){return _vm.$emit('redirect-button', {
            row: row.item.codigo,
          })}}},[_c('b-icon-file-arrow-up-fill',{staticClass:"icon-table-inner"})],1):_vm._e(),(_vm.visualize_pdf)?_c('div',{staticClass:"btn-table-inner btn-table-inner-visualize",on:{"click":function($event){return _vm.$emit('visualize-pdf', {
            row: row.item.codigo,
          })}}},[_c('b-icon-file-earmark-text',{staticClass:"icon-table-inner"})],1):_vm._e(),(row.item.status)?_c('div',{staticClass:"btn-table-inner btn-table-inner-delete",on:{"click":function($event){return _vm.$emit('delete-register', {
            codigo: row.item.codigo,
          })}}},[_c('b-icon-trash',{staticClass:"icon-table-inner"})],1):_vm._e()]}},{key:"cell(collapse)",fn:function(row){return [(
          row.item.collapse !== undefined &&
            row.item.collapse !== null &&
            row.item.collapse.length > 0
        )?_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(row.item.name),expression:"row.item.name"}],staticClass:"btn-table-inner btn-table-inner-options",on:{"click":row.toggleDetails}},[(_vm.changeIcon)?_c('b-icon-box-arrow-up',{staticClass:"icon-table-inner"}):_c('b-icon-box-arrow-down',{staticClass:"icon-table-inner"})],1):_vm._e()]}},{key:"row-details",fn:function(row){return [_c('div',{staticClass:"table-collapse"},[_c('b-table',{attrs:{"fields":_vm.fieldsCampos,"items":row.item.collapse,"no-border-collapse":"","responsive":"lg","striped":""},scopedSlots:_vm._u([{key:"cell(cam_tipo)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("type")(row.item.cam_tipo))+" ")]}}],null,true)})],1)]}}])}),_c('div',{staticStyle:{"padding-bottom":"1em"}},[_vm._v(" "+_vm._s(_vm.noResult)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }