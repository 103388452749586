var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"compModal"}},[_c('b-modal',{ref:"modal",attrs:{"id":_vm.id,"ok-only":"","okVariant":"danger","centered":"","header-bg-variant":"dark","header-text-variant":"light","size":"xl","title":'Ordem de produção ' + _vm.item.op_codigo + ' - Status: ' + _vm.item.status[0].st_descricao},on:{"ok":_vm.handleOk}},[[_c('span',[_c('b',[_vm._v("Produto: ")]),_vm._v(" "+_vm._s(_vm.item.op_fk_pve_final)+" - "+_vm._s(_vm.item.finished_product.product_variety.product_mobile.pro_descricao)+" "),_c('br'),_c('b',[_vm._v("Unidade de medida: ")]),_vm._v(" "+_vm._s(_vm.item.finished_product.measurement_unit.unm_descricao)),_c('br'),_c('b',[_vm._v("Quantidade prevista: ")]),_vm._v(" "+_vm._s(_vm.item.op_quantidade)+" "),_c('br'),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.status[0].st_descricao == 'Finalizado'),expression:"item.status[0].st_descricao == 'Finalizado'"}]},[_c('b',[_vm._v("Quantidade realizada: ")]),_vm._v(" "+_vm._s(_vm.item.op_quantidade_produzida)),_c('br')]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.op_observacao != null),expression:"item.op_observacao != null"}]},[_c('b',[_vm._v("Observação: ")]),_vm._v(" "+_vm._s(_vm.item.op_observacao)),_c('br')])]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.itemComp,"loading":_vm.loading,"loading-text":"Carregando..."},scopedSlots:_vm._u([{key:"item.opi_quantidade_inicial",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"text-align":"right"}},[_c('span',[_vm._v(_vm._s(item.opi_quantidade_inicial))])])]}},{key:"item.feedstock.product_variety.prv_valor_compra",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"text-align":"right"}},[_c('span',[_vm._v(_vm._s(_vm.valorMonetario(item.opi_valor_inicial/item.opi_quantidade_inicial)))])])]}},{key:"item.opi_valor_inicial",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"text-align":"right"}},[_c('span',[_vm._v(_vm._s(_vm.valorMonetario(item.opi_valor_inicial)))])])]}},{key:"item.opi_controla_estoque",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.opi_controla_estoque == 0)?_c('span',[_vm._v("Não")]):_vm._e(),(item.opi_controla_estoque == 1)?_c('span',[_vm._v("Sim")]):_vm._e()])]}},(_vm.total)?{key:"body.append",fn:function(){return [_c('tr',[_c('td',{staticStyle:{"text-align":"right"},attrs:{"colspan":"5"}},[_c('span',[_vm._v("Total por unidade produzida (R$):")])]),_c('td',{staticStyle:{"text-align":"right"},attrs:{"colspan":"1"}},[_c('span',[_vm._v(_vm._s(_vm.valorMonetario(_vm.totalValue)))])])])]},proxy:true}:null],null,true)})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }