<template>
  <div id="compModal">
    <b-modal
        :id="id"
        centered
        ok-only
        okVariant="danger"
        header-bg-variant="dark"
        header-text-variant="light"
        ref="modal"
        size="xl"
        :title="'Composição do produto: ' + item.pve_codigo + ' - ' + item.product_complete_name"
    >
        <template>
          <v-data-table
              hide-default-footer
              :headers="headers"
              :items="item.composition"
              class="elevation-1"
            >
            <template v-slot:item.pcp_quantidade="{item}">
              <div class="align-right">
                <span>{{item.pcp_quantidade}}</span>
              </div>
            </template>

            <template v-slot:item.pcp_total="{item}">
              <div class="align-right">
                <span>{{valorMonetario(item.pcp_total)}}</span>
              </div>
            </template>

            <template v-slot:item.pcp_valor_unitario="{item}">
              <div class="align-right">
                <span>{{valorMonetario(item.pcp_valor_unitario)}}</span>
              </div>
            </template>
            
            <template v-slot:item.pcp_controla_estoque="{item}">
              <div>
                <span v-if="item.pcp_controla_estoque == 0">Não</span>
                <span v-if="item.pcp_controla_estoque == 1">Sim</span>
              </div>
            </template>
            <template v-if="total" v-slot:body.append>
              <tr>
                <td colspan="5" style="text-align:right">
                  <span >Total por unidade produzida (R$):</span>
                </td>
                <td colspan="1" class="align-right">
                  <span>{{ valorMonetario(totalValue) }}</span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
    </b-modal>
  </div>
</template>

<script>
import ProductListForm from "@/components/list-components/ProductListForm"
import GenericTable from "@/components/tables/GenericTable";

export default {
  name: 'Modal',
  components: {
    GenericTable,
    ProductListForm
  },
  props: ['id', 'idEdit', 'item'],
  data() {
    return {
      total: true,
      headers: [
        {text: 'Cód.Produto', value:'pcp_fk_pve_codigo_materia_prima', width: '11%'},
        {text: 'Descrição', value: 'product_variety_pack.product_variety.product_mobile.pro_descricao'},
        {text: 'Unidade de medida', value: 'product_variety_pack.measurement_unit.unm_descricao', width: '15%'},
        {text: 'Quantidade', value: 'pcp_quantidade', align: 'end', width: '11%'},
        {text: 'Valor unitário (R$)', value: 'pcp_valor_unitario', align: 'end', width: '10%'},
        {text: 'Total (R$)', value: 'pcp_total', align: 'end', width: '10%'},
        {text: 'Controla estoque', value: 'pcp_controla_estoque', width: '14%'},
      ],
    };
  },
  computed: {
    totalValue() {
        let total = 0;
        if (this.item.composition[0] != null){
          for(var i=0 ; i<this.item.composition.length ; i++){
            total += parseFloat(this.item.composition[i].pcp_total)
          }
        }
        return total.toFixed(2);
    },
  },
  created () {
      this.initialize()
    },
  methods: {
    initialize () {
      this.item.composition
    },
    valorMonetario(valor){
      return Number(valor).toFixed(2);
    },
  },
};
</script>

<style scoped>
input:focus {
  /*border-color: #28a745 !important;*/
  box-shadow: none !important;
}

.formBordas{
  border: solid medium rgba(0, 0, 0, 0.500);
  padding: 10px;
}

.align-right{
  text-align: right;
}

</style>
