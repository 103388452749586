<template>
  <div id="Modal">
    <b-modal
        :id="id"
        @ok="handleOk"
        @cancel="handleCancel"
        cancelTitle="Cancelar"
        cancelVariant="outline-secondary"
        centered
        header-bg-variant="dark"
        header-text-variant="light"
        okTitle="Salvar"
        okVariant="danger"
        ref="modal"
        size="xl"
        title="Cadastro de Ordem de Produção"
    >
      <form @submit.stop.prevent="handleSubmit" ref="form">
        <div class="formBordas">
        <ProductSearchComp
          class="campoObrigatorio"
          :disabled="disabledEdit"
          :labelCode="labelCode.toString()"
          :labelCodeRef="labelCodeRef"
          :labelCodeBarras="labelCodeBarras"
          :labelName="labelName"
          CampoObrigatorio= true
          @update:value="produtoProducao = $event"/>
          <b-row>
            <b-col cols="12" sm="3" md="3">
              <label>Unidade de medida:</label>
              <v-text-field
                solo
                disabled
                v-model="produtoProducao.measurement_unit.unm_descricao">
              </v-text-field>
            </b-col>
            <b-col cols="12" sm="3" md="3">
              <label>Quantidade</label>
              <v-text-field
                class="campoObrigatorio right-input"
                solo
                type="number"
                required
                v-model="quantidade">
                <template v-slot:prepend-inner>
                  <img
                    style="vertical-align:top;"
                    width="20"
                    height="49"
                    alt=""
                    src="../../assets/faixaCampoObrigatorio.png"
                  />
                </template>
              </v-text-field>
            </b-col>
            <b-col cols="12" sm="3" md="3">
              <label>Data:</label>
              <div>
                <DefaultDatePicker
                class="campoObrigatorio"
                :label="labelData_producao"
                CampoObrigatorio= true
                @update:returnDate="data_producao = $event"/>
                {{data_producaoEdit()}}
              </div>
            </b-col>
            <b-col cols="12" sm="3" md="3">
              <label>Hora:</label>
              <v-text-field
                class="campoObrigatorio"
                type="time"
                solo
                required
                v-model="hora_producao">
                <template v-slot:prepend-inner>
                  <img
                    style="vertical-align:top;"
                    width="20"
                    height="49"
                    alt=""
                    src="../../assets/faixaCampoObrigatorio.png"
                  />
                </template>
              </v-text-field>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="12" md="12">
              <label>Observação:</label>
              <v-textarea
                  solo
                  v-model="obsProducao"
                  />
            </b-col>
          </b-row>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import swal from 'sweetalert2';
import ProductSearchComp from "@/components/search-components/ProductSearchComp";
import ProductListForm from "@/components/list-components/ProductListForm"
import GenericTable from "@/components/tables/GenericTable";
import apiInstance from "../../services/api/config";
import DefaultDatePicker from '@/components/generics/DefaultDatePicker';

export default {
  name: 'Modal',
  components: {
    GenericTable,
    ProductSearchComp,
    ProductListForm,
    DefaultDatePicker,
  },
  props: ['id', 'edit', 'item', 'limpaDados'],
  data() {
    return {
      produtoProducao:{
        product_complete_name: '',
        pve_codigo: '',
        pve_codigo_referencia: '',
        pve_codigo_barras: '',
        measurement_unit:{unm_descricao: ''},
      },
      quantidade: '',
      data_producao: null,
      labelData_producao: '',
      hora_producao: null,
      obsProducao: '',
      disabledEdit: false,
      labelName: '',
      labelCodeBarras: '',
      labelCodeRef: '',
      labelCode: '',
      dataForEdit: '',
    };
  },
  watch: {
    edit() {
      if(this.edit != 0){
        this.disabledEdit = true;
        this.produtoProducao = {
          product_complete_name: this.item.finished_product.product_complete_name,
          pve_codigo: this.item.finished_product.pve_codigo,
          pve_codigo_referencia: this.item.finished_product.pve_codigo_referencia,
          pve_codigo_barras: this.item.finished_product.pve_codigo_barras,
          measurement_unit:{unm_descricao: this.item.finished_product.measurement_unit.unm_descricao},
        };
        this.labelName = this.produtoProducao.product_complete_name;
        this.labelCodeBarras = this.produtoProducao.pve_codigo_barras;
        this.labelCodeRef = this.produtoProducao.pve_codigo_referencia;
        this.labelCode = this.produtoProducao.pve_codigo;
        this.quantidade = this.item.op_quantidade;
        this.data_producao = this.item.op_dta_agendamento;
        this.hora_producao = this.item.op_hora_agendamento;
        this.obsProducao = this.item.op_observacao;
        this.dataForEdit = '';
      }
    },
    limpaDados(){
      this.handleCancel();
    },
  },
  methods: {
    formataData(data){
      return moment(String(data)).format('DD/MM/YYYY')
    },
    data_producaoEdit(){
      if(this.edit != 0){
        this.labelData_producao = this.formataData(this.item.op_dta_agendamento);
        this.data_producao = this.item.op_dta_agendamento;
      }
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.produtoProducao.pve_codigo == '') {
        swal.fire(
            '',
            'Por favor, adicione um produto para continuar',
            'warning',
        );
        return;
      }else if(!this.edit){
        if (this.produtoProducao.composition.length == 0) {
        swal.fire(
            '',
            'Produto informado não contem composição, informe outro produto ou adicione uma composição, em cadastro de composição, para continuar',
            'warning',
        );
        return;
      };
      }else if (this.quantidade == null) {
        swal.fire(
            '',
            'Por favor, informe a quantidade para continuar',
            'warning',
        );
        return;
      }else if (this.data_producao == null) {
        swal.fire(
            '',
            'Por favor, informe a data da produção para continuar',
            'warning',
        );
        return;
      }else if (this.hora_producao == null) {
        swal.fire(
            '',
            'Por favor, informe a hora para continuar',
            'warning',
        );
        return;
      }else if(this.edit == 0){
        if(this.produtoProducao.product_variety.product_mobile.pro_status == 0 || this.produtoProducao.product_variety.prv_status == 0){
          swal.fire(
            '',
            'O produto selecionado está inativo. Selecione outro produto ou altere o status no cadastro do produto.',
            'warning',
        );
        return
        }
      }
      this.handleSubmit();
    },
    handleSubmit() {
      swal.fire({
          title: '',
          html:
            '<span class="mdi mdi-spin mdi-loading"></span>' +
            ' Salvando...',
          showConfirmButton: false,
        });
      const obj = 
        {
          "op_fk_pve_final": this.produtoProducao.pve_codigo,
          "op_dta_agendamento": this.data_producao,
          "op_hora_agendamento": this.hora_producao,
          "op_quantidade": this.quantidade,
          "op_observacao": this.obsProducao,
        }

      if (this.edit != 0) {
        apiInstance
          .put('/production-order/'+ this.item.op_codigo, obj)
          .then((response) => {
            swal
                .fire({
                  title: '',
                  text: 'Ordem de Produção atualizada com sucesso !',
                  icon: 'success',
                  timer: 2000,
                })
                .then(res => {
                  if (res) {
                    location.reload();
                  }
                });
          })
          .catch((error) => {
            console.log(error);
          })
      } else {
        apiInstance
          .post('/production-order', obj)
          .then((response) => {
            swal
                .fire({
                  title: '',
                  text: 'Ordem de Produção cadastrada com sucesso !',
                  icon: 'success',
                  timer: 2000,
                })
                .then(res => {
                  if (res) {
                    location.reload();
                  }
                });
          })
          .catch((error) => {
            console.log(error);
          })
        };
        this.produtoProducao = {};
    },
    handleCancel(){
      this.produtoProducao = {
        product_complete_name: '',
        pve_codigo: '',
        pve_codigo_referencia: '',
        pve_codigo_barras: '',
        measurement_unit:{unm_descricao: ''},
      };
      this.quantidade = '';
      this.data_producao = null;
      this.hora_producao = null;
      this.obsProducao = '';
      this.disabledEdit = false;
      this.labelName = '';
      this.labelCodeBarras = '';
      this.labelCodeRef = '';
      this.labelCode = '';
      this.dataForEdit = '';
    }
  },
};
</script>

<style scoped>

.modal__title {
  text-align: rigth;
  font-size: 20px;
}

.table-padding {
  padding-bottom: 1em;
}

input:focus {
  /*border-color: #28a745 !important;*/
  box-shadow: none !important;
}

.btn-table-inner {
  display: inline-block;
  width: 2.5em;
  height: 2.5em;
}

.btn-table-inner-delete {
  color: #ffffff;
  border: solid 1px #dc3545;
  border-radius: 0.25rem;
  background: #dc3545;
}

.btn-table-inner-delete:hover {
  color: #dc3545;
  border-color: #dc3545;
  background-color: #ffffff;
  cursor: pointer;
}

.icon-table-inner {
  fill: currentColor;
  width: 2em;
  height: 2em;
  margin: 0.25em;
}

.btn-push-option {
  display: flex;
  align-items: flex-end;
  justify-items: flex-end;
}

.formBordas{
  border: solid medium rgba(0, 0, 0, 0.500);
  padding: 10px;
}

.submit {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}

.botaoAlterar{
  margin-top:37px;
}

.campoObrigatorio >>> div.v-input__slot{
  padding: 0px 12px 0px 0px !important;
}

.right-input >>> input {
  text-align: right;
  }

</style>
