var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"compModal"}},[_c('b-modal',{ref:"modal",attrs:{"id":_vm.id,"centered":"","ok-only":"","okVariant":"danger","header-bg-variant":"dark","header-text-variant":"light","size":"xl","title":'Composição do produto: ' + _vm.item.pve_codigo + ' - ' + _vm.item.product_complete_name}},[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.item.composition},scopedSlots:_vm._u([{key:"item.pcp_quantidade",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"align-right"},[_c('span',[_vm._v(_vm._s(item.pcp_quantidade))])])]}},{key:"item.pcp_total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"align-right"},[_c('span',[_vm._v(_vm._s(_vm.valorMonetario(item.pcp_total)))])])]}},{key:"item.pcp_valor_unitario",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"align-right"},[_c('span',[_vm._v(_vm._s(_vm.valorMonetario(item.pcp_valor_unitario)))])])]}},{key:"item.pcp_controla_estoque",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.pcp_controla_estoque == 0)?_c('span',[_vm._v("Não")]):_vm._e(),(item.pcp_controla_estoque == 1)?_c('span',[_vm._v("Sim")]):_vm._e()])]}},(_vm.total)?{key:"body.append",fn:function(){return [_c('tr',[_c('td',{staticStyle:{"text-align":"right"},attrs:{"colspan":"5"}},[_c('span',[_vm._v("Total por unidade produzida (R$):")])]),_c('td',{staticClass:"align-right",attrs:{"colspan":"1"}},[_c('span',[_vm._v(_vm._s(_vm.valorMonetario(_vm.totalValue)))])])])]},proxy:true}:null],null,true)})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }